import React from 'react';
import './OurMission.css';

function OurMission() {
    return (
        <div className="about-container">
             <h2 className="about-title"> <b>Our Mission </b></h2>
             <p>⮞ To source a range of quality fruits and nuts from across the globe and deliver them fresh to customers across India.</p>
        </div>
    );
}

export default OurMission;
