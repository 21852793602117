import { Container, Row, Col } from "react-bootstrap";
import './footer.css';
const Footer = () => {
   

    return (
        <footer style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#99CCCC' }}>
            <Container style={{ width: '100%' }}>
    <Row>
    <Col className="custom-footer">
    <p> Trading Quality Fresh Produce from around the world</p>
</Col>
    </Row>
</Container>

        </footer>
    )
}

export default Footer;
