import React from 'react';
import './avocado.css';
import avocadoImg from "./img1.jpg"; // Replace with your actual path to the avocado image

function Avocado() {
    return (
        <div className="avocado-container">
            <img src={avocadoImg} alt="Avocado varieties" className="avocado-image" />
            <div className="avocado-description">
                <h2>Avocado Varieties</h2>
                <ul className="avocado-list">
                    <li>⮞ Hass</li>
                    <li>⮞ Fuerte</li>
                    <li>⮞ Pinkerton</li>
                    <li>⮞ Bacon</li>
                    <li>⮞ Zutano</li>
                    <li>⮞ Reed</li>
                </ul>
            </div>
        </div>
    );
}

export default Avocado;
