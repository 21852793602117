import React from 'react';
import './orange.css';
import orangeImg from "./img7.jpg"; // Replace with your actual path to the orange image

function Orange() {
    return (
        <div className="orange-container">
            <img src={orangeImg} alt="Orange varieties" className="orange-image" />
            <div className="orange-description">
                <h2>Orange Varieties</h2>
                <ul className="orange-list">
                    <li>⮞ Navel</li>
                    <li>⮞ Blood Orange</li>
                    <li>⮞ Valencia</li>
                    <li>⮞ Cara Cara</li>
                    <li>⮞ Seville</li>
                    <li>⮞ Hamlin</li>
                </ul>
            </div>
        </div>
    );
}

export default Orange;
