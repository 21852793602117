import React, { useState } from 'react';
import './header.css';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 
import logo from './logo.png';

function Header() {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <div className="header">
            <div className="logo-section">
                <Link to="/">
                    <img src={logo} alt="Divya Ganga Traders Logo" className="logo-image" />
                </Link>
                <span>Divya Ganga Traders</span>  
            </div>

            <div className="hamburger-menu" onClick={() => setShowMenu(!showMenu)}>
                ☰
            </div>

            <nav className={showMenu ? 'active' : ''}>
                <ul>
                    <li>
                        <NavDropdown title="Our Organization" id="company-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/aboutus">About Us</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/ourperspective">Our Perspective</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/ourmission">Our Mission</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/distribution">Distribution</NavDropdown.Item>
                        </NavDropdown> 
                    </li>

                    <li>
                        <NavDropdown title="Product" id="product-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/apples">Apples</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/pears">Pears</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/almonds">Almonds</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/avocado">Avocado</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/orange">Orange</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/cherry">Cherry</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/berries">Berries</NavDropdown.Item>
                        </NavDropdown>
                    </li>

                    <li><Link to="/getintouch">Get in Touch</Link></li>
                </ul>
            </nav>
        </div>
    );
}

export default Header;
