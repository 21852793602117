import React from 'react';
import './apples.css';
import apple from "./img.jpg"; // Replace with your actual path

function Apples() {
    return (
        <div className="apple-container">
            <img src={apple} alt="Apple varieties" className="apple-image" />
            <div className="apple-description">
                <h2>Apples Varieties</h2>
                <ul className="apple-list">
                    <li>⮞ Red Delicious</li>
                    <li>⮞ Pink Lady</li>
                    <li>⮞ Fuji</li>
                    <li>⮞ Gala</li>
                    <li>⮞ Granny Smith</li>
                    <li>⮞ Golden Delicious</li>
                </ul>
            </div>
        </div>
    );
}

export default Apples;
