import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from "./component/Header/header";
import LandingPage from './component/Screens/LandingPage';
import Footer from "./component/Footer/footer";
import ContactUs from './component/Header/ContactUs/ContactUs';
import AboutUs from './component/Header/OurCompany/AboutUs';  
import OurMission from './component/Header/OurCompany/OurMission';
import Distribution from './component/Header/OurCompany/distribution';
import OurVision from './component/Header/OurCompany/OurVision';
import Apples from './component/Header/Product/apples';
import Pears from './component/Header/Product/pears';
import Almonds from './component/Header/Product/almonds';
import Avocado from './component/Header/Product/avocado';
import Orange from './component/Header/Product/orange';
import Berries from './component/Header/Product/berries';
import Cherry from './component/Header/Product/cherry';

function App() {
    return (
        <Router>
            <div id="app">
                <Header /> <br></br>
                <div className="content">
                    <Switch>
                        <Route path="/" exact component={LandingPage} />
                        <Route path="/getintouch" component={ContactUs} />
                        <Route path="/aboutus" component={AboutUs} />  
                        <Route path="/ourmission" component={OurMission} />
                        <Route path="/distribution" component={Distribution} /> 
                        <Route path="/ourperspective" component={OurVision} /> 
                        <Route path="/apples" component={Apples} /> 
                        <Route path="/pears" component={Pears} /> 
                        <Route path="/almonds" component={Almonds} /> 
                        <Route path="/avocado" component={Avocado} /> 
                        <Route path="/orange" component={Orange} /> 
                        <Route path="/berries" component={Berries} /> 
                        <Route path="/cherry" component={Cherry} />
                    </Switch>
                </div>
                <br></br>
                <Footer />  
            </div>
        </Router>
    );
}

export default App;
