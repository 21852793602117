import React from 'react';
import './LandingPage.css';
import img from "./img.jpg"
import img1 from "./img1.jpg"
import img2 from "./img2.jpg"
import img4 from "./img4.jpg"
import img5 from "./img5.jpg"
import img6 from "./img6.jpg"

function LandingPage() {
    const products = [
        { image: img, title: 'Apples' },
        { image: img1, title: 'Avocado' },
        { image: img2, title: 'Pears' },
        { image: img4, title: 'Almonds' },
        { image: img5, title: 'Cherry' },
        { image: img6, title: 'Berries' },
    ];

    return (
        <div className="landing-container">
            <div className="title">
                <h2>Divya Ganga Trading LLP</h2>
                <h5>Quality and Freshness from around the world </h5>
                <p className="intro-animation">Divya Ganga Trading is among one of the emerging importers in India and UAE. We import fresh produce from around the globe. The products includes specialty fruits and nuts. We strive to provide topmost quality, freshness and service to all our customers at reasonable prices. According to our core vision of the business we would like to have long-term presence in the industry and hence client satisfaction is very important to us. AT Divya Ganga, we follow all regulations, quality checks and protocols to ensure that a healthy fruit reaches the consumer. </p>
                  <p> We build our reputation by understanding the needs of our customers and developing products and services based on their demands and interests. Our flexibility allows us to respond to special requirements in addition to the usual market demands, and we always welcome involving inspection companies. our focus has always been grower return through innovative packaging, value added sales through its program business with the retailers and a pro-active approach that its sales force practices.</p>
                  <p> Our approach to our suppliers has been simplified through transparency, honesty and integrity. We negotiate all of our retail business in a triangular mode where the suppliers and retailers interact which facilitates all understanding of one another’s needs.</p>
                  <p> We always consider ourselves committed to using expert and well-trained personnel and insist on continuous training and empowering our employees, gardeners and workers. The management team insists on educating and awareness of the small community surrounding the group. </p>
                  <p> We  do our best to understand the potential problems of our customers, and according to our history in fruit supplying, experience in business, 24/7 availability and the problem-solving spirit of our group, we will offer tranquillity to the counter-party. Customer satisfaction is one of the critical points in our organization. We try to provide first-class services that exceed the customer’s expectations to step in the direction of mutual trust. We support them in the whole process through cooperation if they wish, and we seek to bring them a successful experience.</p>
            </div>
            <div className="card-section">
                {products.map((product, index) => (
                    <div className="card" key={index}>
                        <img src={product.image} alt={product.title} />

                        <h3 className="card-title-animation">{product.title}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LandingPage;
