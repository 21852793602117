import React from 'react';
import './almonds.css';
import almond from "./img4.jpg"; // Replace with your actual path to the almond image

function Almonds() {
    return (
        <div className="almond-container">
            <img src={almond} alt="Almond varieties" className="almond-image" />
            <div className="almond-description">
                <h2>Almond Varieties</h2>
                <ul className="almond-list">
                    <li>⮞ Nonpareil</li>
                    <li>⮞ Butte & Padre</li>
                    <li>⮞ Carmel</li>
                    <li>⮞ Tuono</li>
                    <li>⮞ Marcona</li>
                    <li>⮞ Sonora</li>
                </ul>
            </div>
        </div>
    );
}

export default Almonds;
