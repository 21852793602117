import React from 'react';
import './berries.css';
import berriesImg from "./img6.jpg"; // Replace with your actual path to the berries image

function Berries() {
    return (
        <div className="berries-container">
            <img src={berriesImg} alt="Berries varieties" className="berries-image" />
            <div className="berries-description">
                <h2>Berries Varieties</h2>
                <ul className="berries-list">
                    <li>⮞ Blueberries</li>
                    <li>⮞ Strawberries</li>
                    <li>⮞ Raspberries</li>
                    <li>⮞ Blackberries</li>
                    <li>⮞ Cranberries</li>
                    <li>⮞ Goji Berries</li>
                </ul>
            </div>
        </div>
    );
}

export default Berries;
