import React from 'react';
import './OurVision.css';

function OurVision() {
    return (
        <div className="vision-container">
             <h2 className="about-title"> <b>Our Perspective</b></h2>
            <h5>To be the most trusted and preferred Indian fruit trading business.</h5>
            
            <div className="vision-categories">
                <h6>Growers:</h6>
                <p>⮞ By enabling their fruits reach from their farms to different regions in India</p>

                <h6>Wholesalers:</h6>
                <p>⮞ By ensuring they receive fruits fresh, every day, every time</p>

                <h6>Consumers:</h6>
                <p>⮞ By satiating their taste preferences through a gamut of fruit offerings</p>
            </div>
        </div>
    );
}

export default OurVision;
