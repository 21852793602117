import React from 'react';
import "./ContactUs.css"
function ContactUs() {
    return (
        <div className="contact-container">
            <h2 className="about-title"> <b>Get in Touch</b></h2>
            
            <div className="static-info">
                <h3>CONTACT HEADQUARTERS</h3> 
                <h5><strong>India</strong></h5>
                <p><strong>Location:</strong> Gujarat , India</p>
                <p><strong>Company:</strong> Divya Ganga Trading LLP</p>
                <p><strong>Address:</strong>Shop 12,13,14 and 15, Diwali Blessings Vaishnodevi, Ahmedabad, Gujarat INDIA 382421</p>
                <p><strong>Phone:</strong> +91 898-091-2929</p>
                <p><strong>Mail:</strong>info@divyagangatradingllp.com</p>
                <br></br>
                <h5><strong>United Arab Emirates </strong></h5>
                <p><strong>Company:</strong> Divya Ganga International FZ-LLC </p>
                <p><strong>Address:</strong> FDCW1942 Compass Building, Al Shohada Road, Al Hamra Industrial Zone-FZ, Ras Al Khaimah, UAE</p>
                <p><strong>Phone:</strong> +97 158-855-6922</p>
            </div>
        </div>
    );
}

export default ContactUs;
