import React from 'react';
import './cherry.css';
import cherryImg from "./img5.jpg"; // Replace with your actual path to the cherry image

function Cherry() {
    return (
        <div className="cherry-container">
            <img src={cherryImg} alt="Cherry varieties" className="cherry-image" />
            <div className="cherry-description">
                <h2>Cherry Varieties</h2>
                <ul className="cherry-list">
                    <li>⮞ Bing</li>
                    <li>⮞ Lambert</li>
                    <li>⮞ Rainier</li>
                    <li>⮞ Tulare</li>
                    <li>⮞ Royal Ann</li>
                    <li>⮞ Black Tartarian</li>
                </ul>
            </div>
        </div>
    );
}

export default Cherry;
