import React from 'react';
import './AboutUs.css';

function AboutUs() {
    return (
        <div className="about-container">
            <h2 className="about-title"><b>About Us</b></h2>
            <p> Welcome to Divya Ganga</p>
            <p> Officially established in 2019, Our experience and heritage within the fruit industry in India is second to none. Keeping our roots intact, we represent the same passion for quality, service and values that we were built on. Today, Divya Ganga imports fresh, wholesome and high quality fruits straight from the orchards of the farthest corners of the world. Therefore, it makes us the best Fruits and Nuts Importers and Dealers.</p>
            <p> We are an emerging Fruits and Nuts Traders and Importer in India. We have expertise in sourcing the choicest of fruits from around the world and distributing it across the length and breadth of India. Our top-notch distribution network strengthened through years of existence in the fruit business, works efficiently to ensure the fruits are delivered far and wide with an intriguing depth of freshness.</p>
            <p> Over the years, we have grown consistently and evolved with the changing needs, crafting ourselves to satiate the taste buds of fruit consumers in India.We are proud of our reputation for providing fresh, high quality produce, including Apples, citrus, stone fruit, tropical fruits, pineapples, berries and Almonds and Pistachios.</p>
            <p> We have exclusive tie ups with the leading fruit growers and packers worldwide, is a testimony to the company’s commitment to sourcing and delivering quality fruits. Mutual trust and reliability with stakeholders is a key feature to Divya Ganga's continued success and growth.</p>
        </div>
    );
}

export default AboutUs;
