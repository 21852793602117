import React from 'react';
import './distribution.css';

function distribution() {
    return (
        <div className="importProgram-container">
            <h2 className="about-title"> <b>DISTRIBUTION </b></h2>
          
          <p>⮞ Our efficient supply chain has enabled us to have a large presence across the country. Divya Ganga Trading Company has key hubs in major cities of Gujarat. </p>

          <p>⮞ We have built a strong retail market presence to serve our consumers better in different parts of the country.</p>

          <p>⮞ We have a vast distribution network comprising of highly dedicated professionals coupled with strong logistics support, establishing a nationwide reach.</p>
 
            
        </div>
    );
}

export default distribution;
