import React from 'react';
import './pears.css';
import pears from "./img2.jpg"; // Replace with your actual path to the pear image

function Pears() {
    return (
        <div className="pear-container">
            <img src={pears} alt="Pear varieties" className="pear-image" />
            <div className="pear-description">
                <h2>Pear Varieties</h2>
                <ul className="pear-list">
                    <li>⮞ Bosc</li>
                    <li>⮞ Bartlett</li>
                    <li>⮞ Anjou</li>
                    <li>⮞ Comice</li>
                    <li>⮞ Seckel</li>
                    <li>⮞ Forelle</li>
                </ul>
            </div>
        </div>
    );
}

export default Pears;
